<template>
  <component :is="componentToShow"
             :to="cta ? cta.href : ''"
             :target="cta && cta.href && cta.href.toLowerCase().startsWith('http') ? '_blank' : ''"
             class="relative flex flex-col md:flex-row justify-end md:justify-start w-full h-full overflow-hidden text-white bg-cover shadow-2xl rounded-xl group md:aspect-w-19 md:aspect-h-10 min-h-[512px] lg:min-h-full"
  >
    <!-- Wrapper for @tailwindcss/aspect-ratio padding trick -->
    <div class="flex flex-col md:flex-row justify-end md:justify-start w-full h-full p-6 sm:p-8 md:p-12 lg:p-16 min-h-[512px] lg:min-h-full">
      <!-- Clip path for Safari mouse over effect, to clip rounded corners -->
      <div v-if="backgroundImage || backgroundImageMobile"
           class="absolute inset-0 w-full h-full overflow-hidden rounded-xl"
           :style="{ clipPath: 'content-box' }"
      >
        <NuxtImg v-if="isDesktop"
                 :alt="title || ''"
                 :src="backgroundImage"
                 class="object-cover w-full h-full transition duration-300 transform"
                 :class="cta && cta.href ? 'group-hover:scale-110' : ''"
                 sizes="xs:87vw sm:87vw md:46vw lg:1140px xl:1140px xxl:1140px"
                 loading="lazy"
        />
        <NuxtImg v-else
                 :src="backgroundImageMobile ? backgroundImageMobile : backgroundImage"
                 :alt="title || ''"
                 class="object-cover w-full h-full transition duration-300 transform"
                 :class="cta && cta.href ? 'group-hover:scale-110' : ''"
                 sizes="xs:87vw sm:87vw md:87vw lg:1140px xl:1140px xxl:1140px"
                 fit="inside"
                 loading="lazy"
        />
      </div>
      <div class="absolute inset-0 w-full h-full overflow-hidden transform rounded-xl"
           :style="{
             background: 'linear-gradient(360deg, rgba(10, 10, 10, 0.8) 0%, rgba(10, 10, 10, 0) 50%)',
             clipPath: 'content-box',
           }"
      />
      <div class="flex flex-col justify-between flex-grow">
        <div class="relative">
          <NuxtImg v-if="logo"
                   :src="logo"
                   alt="Logo"
                   class="h-20 sm:h-16 lg:h-24"
                   sizes="xs:200px sm:200px md:200px lg:200px xl:200px xxl:200px"
                   loading="lazy"
          />
        </div>
        <div class="max-w-[90%] md:max-w-md lg:max-w-lg">
          <h3 class="relative text-2xl sm:text-3xl md:text-4xl lg:text-5xl lg:leading-tight"
              v-html="title"
          />
          <div v-if="copy"
               class="relative mt-2 font-normal md:mt-6"
               v-html="copy"
          />
        </div>
      </div>
      <ButtonBase v-if="cta"
                  :type="cta.type"
                  :label="cta.title"
                  :href-type="hrefType"
                  class="relative flex flex-shrink-0 mt-8 pointer-events-none md:ml-4 md:flex-grow md:justify-end md:items-end"
      />
    </div>
  </component>
</template>

<script setup lang="ts">
import { computed, resolveComponent } from 'vue'
import { useLinkType } from '../../composables/link-type'
import type { CtaType } from '../../types/button'
import { useScreenSize } from '~/composables/use-screen-size'

type CardProductProps = {
  title?: string
  copy?: string
  cta?: CtaType
  logo?: string
  backgroundImage: string
  backgroundImageMobile?: string
}

const props = defineProps<CardProductProps>()

const { isDesktop } = useScreenSize()

const componentToShow = computed(() => {
  if (props.cta && props.cta.href && props.cta.href.length > 0) {
    return resolveComponent('NuxtLink')
  }
  return 'div'
})

const hrefType = computed(() => {
  if (props.cta && props.cta.href && props.cta.href.length > 0) {
    return useLinkType(props.cta.href)
  }

  return null
})
</script>
